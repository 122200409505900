import Vue from 'vue';
import CONSTANTS from './constants';
import { StateType } from './types';

export default {
  // Conversions
  [CONSTANTS.SET_CONVERSIONS](state: StateType, conversions): void {
    Vue.set(state, 'conversions', conversions);
  },
  [CONSTANTS.SET_CONVERSIONS_ERROR](state: StateType, error): void {
    Vue.set(state, 'conversionsError', error);
  },
  [CONSTANTS.SET_IS_CONVERSIONS_LOADING](state: StateType, isLoading): void {
    Vue.set(state, 'isConversionsLoading', isLoading);
  },
  [CONSTANTS.SET_CONVERSIONS_CACHE](state: StateType, conversionsCache): void {
    Vue.set(state, 'conversionsCache', conversionsCache);
  },
  [CONSTANTS.SET_IS_CONVERSIONS_CACHED](state: StateType, isConversionsCached): void {
    Vue.set(state, 'isConversionsCached', isConversionsCached);
  },

  // DailyPerformance
  [CONSTANTS.SET_DAILY_PERFORMANCE](state: StateType, dailyPerformance): void {
    Vue.set(state, 'dailyPerformance', dailyPerformance);
  },
  [CONSTANTS.SET_DAILY_PERFORMANCE_ERROR](state: StateType, error): void {
    Vue.set(state, 'dailyPerformanceError', error);
  },
  [CONSTANTS.SET_IS_DAILY_PERFORMANCE_LOADING](state: StateType, isLoading): void {
    Vue.set(state, 'isDailyPerformanceLoading', isLoading);
  },
};
