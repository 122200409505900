import mutations from './mutations';
import actions from './actions';
import { StateType } from './types';

const state: StateType = {
  conversions: null,
  conversionsCache: null,
  isConversionsCached: false,
  conversionsError: null,
  isConversionsLoading: false,

  dailyPerformance: null,
  dailyPerformanceError: null,
  isDailyPerformanceLoading: false,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
