export default {
  EVENT_NAV: 'UserNav',
  TRACK_NAV_OPEN: 'Open menu',
  TRACK_NAV_CHANGE_PASSWORD: 'Change password',
  TRACK_NAV_MANAGE_USERS: 'Manage Users',
  TRACK_NAV_MANAGE_ADVERTISERS: 'Manage Advertisers',
  TRACK_NAV_MANAGE_AGENCIES: 'Manage Agencies',
  TRACK_NAV_MANAGE_SYSTEM: 'Manage System',
  TRACK_NAV_LOGOUT: 'Logout',
  TRACK_NAV_SHARE_DASHBOARD: 'Share Dashboard',
  TRACK_NAV_SHARE_DASHBOARD_USED: 'Shared Dashboard Used',
  TRACK_NAV_MANAGE_REPORTS: 'Manage Reports',
  TRACK_NAV_EDIT_LAYOUT: 'Edit Layouts',
  TRACK_NAV_ADVERTISER_VIEW: 'Advertiser View',
  // UserNav - Export tracking in component - adminMenu.vue (exportDashboard method)
  EVENT_NAV_TACTICS: 'TacticNav',
  TRACK_NAV_TOGGLE_TACTICS: 'Toggle Menu',
  // TacticNav - Tabs tracked in component - taticMenu.vue (setCurrentSection method)
  EVENT_NAV_OPTIONS: 'OptionsNav',
  TRACK_NAV_TOGGLE_OPTIONS: 'Toggle Menu',
  TRACK_NAV_DARK_MODE: 'Toggle Dark Mode',
  TRACK_NAV_FULLSCREEN: 'Fullscreen',
  TRACK_NAV_EDIT_ADVERTISER: 'Edit Advertiser',
  EVENT_NAV_DATE: 'DateRangeNav',
  TRACK_NAV_TOGGLE_DATE_RANGE: 'Toggle Menu',
  TRACK_NAV_GO_TO_MANAGE_REPORTS: "Open Advertiser's Scheduled Reports",

  // DateRangeNav - List tracked in component - globalDateSelector.vue (selectRange method)
  EVENT_NAV_ADVERTISER: 'AdvertiserNav',
  // AdvertiserNav - Advertiser Search tracked in component - advertiserDetails.vue (selectAdvertiser method)
  EVENT_NAV_CAMPAIGN: 'CampaignNav',
  TRACK_CAMPAIGN_NAV_NEXT: 'Next Campaign',
  TRACK_CAMPAIGN_NAV_PREVIOUS: 'Previous Campaign',
  TRACK_CAMPAIGN_NAV_SELECT_ALL: 'Select All',
  TRACK_CAMPAIGN_NAV_DESELECT_ALL: 'Deselect All',
  TRACK_CAMPAIGN_NAV_SELECT_ONE: 'Select One',
  TRACK_CAMPAIGN_NAV_SELECT_MULTIPLE: 'Select Multiple',
  TRACK_CAMPAIGN_NAV_TOGGLE_ONLY_MATCHING_CAMPAIGNS: 'Toggle only matching campaigns',
  // CampaignDateNav date picker tracked in component - campaignDateSelector.vue (selectRange method)
  EVENT_NAV_EXPORT: 'ExportNav',
  TRACK_NAV_EXPORT_TOGGLE: 'Toggle Menu',
  TRACK_NAV_EXPORT_ALL_CAMPAIGNS: 'All Campaigns',
  TRACK_NAV_EXPORT_SELECTED_CAMPAIGNS: 'Selected Campaigns',
  TRACK_NAV_EXPORT_SHARED_CAMPAIGNS: 'Shared Campaigns',
  TRACK_NAV_SBG_HELP: 'Go to Help Center',
  // Edit Layout
  EVENT_EDIT_LAYOUT: 'EditLayout',
  TRACK_CUSTOMIZATIONS_SAVE: 'Save Customizations',
  // Edit Reports
  EVENT_MANAGE_REPORTS: 'ManageReports',
  TRACK_REPORT_SAVE: 'Save Report',
  TRACK_REPORT_DELETE: 'Delete Report',
  EVENT_MR_CAMPAIGNS: 'MR-Campaigns',
  EVENT_MR_FREQUENCY: 'MR-Frequency',
  EVENT_MR_FILE_TYPE: 'MR-FileType',
  // Edit Advertiser Info
  EVENT_EDIT_ADVERTISER_INFO: 'EditAdvertiserInfo',
  TRACK_EDIT_ADVERTISER_LOGO: 'Edit Advertiser Logo',
  // Password Reset
  EVENT_USER_PASSWORD: 'User Password Management',
  TRACK_REQUEST_PASSWORD_RESET: 'Request Password Reset',
  // Campaign Notes
  TRACK_CAMP_NOTES: 'CampaignNotes',
  CAMP_NOTE_CREATED: 'Note Created',
  CAMP_NOTES_VIEWED: 'Notes Viewed',
  CAMP_NOTE_EDITED: 'Note Edited',
  CAMP_NOTE_DELETED: 'Note Deleted',
  // Configurability
  EVENT_CONFIGURABILITY: 'EL-Configurability',
  EVENT_CHANGE_MODULE_SETTING: 'EL-Change Module Setting',
  EVENT_ADJUST_WIDTH: 'EL-Adjust Module Width',
  EDIT_MODULE: 'EL-Edit Module',
  MOVE_MODULE: 'EL-Move Module',
  REMOVE_MODULE: 'EL-Remove Module',
  SAVE_MODULE: 'EL-Save Module',
  ADD_MODULE: 'EL-Add Module',
  SAVE_CHANGE_TO: 'EL-Save Change To',
  EXIT_WITHOUT_SAVING: 'EL-Exit Without Saving Changes',
  RESET_TO: 'EL-Reset To',
  // C360
  EVENT_GO_TO_MP: 'C360-Go to Mediaplanner',
  EVENT_GO_TO_IO: 'C360-Go to Instant IO',
  EVENT_GO_TO_HC: 'C360-Go to HelpCenter',
  EVENT_RETURN_TO: 'C360-Return to portal',
  // AHLOGIN
  AH_LOGIN: 'Dashboard Login - AH',
  // Post Login
  POST_LOGIN: 'PostLogin',
  VIEW: 'Viewing the main landing page',
  CHANGE_DATERANGE: 'Change daterange',
  CHANGE_SORTING: 'Change sort order',
  PERFORMANCE_OVERVIEW_CHANGE_GRAPH: 'Performance Overview change graph selector',
  CHANGE_FILTER_BY: 'CHANGE_FILTER_BY',
  // ORDERS
  ORDERS_VIEW: 'Order List - Viewing the page',
  ORDERS_BACK_TO_LIST: 'Order Details - Return to Orders List',
  ORDERS_DETAILS_CHANGE_DATERANGE: 'Order Details - Change date range',
  ORDERS_CHANGE_DATERANGE: 'Order List - Change date range',
  ORDERS_FILTER_BY: 'Orders List - Filter By Type',
  ORDERS_CHANGE_SORT: 'Orders List - Change Sorting',
  ORDERS_GO_TO_DETAILS: 'Orders List - Go to Order Details',
  ORDERS_GO_TO_CAMPAIGN: 'Order Details - Go to Campaign',
  ORDERS_CHANGE_DELIVERED_IMPS: 'Order Details - Change Delivered Imps Granularity',
  ORDERS_SELECT_PRODUCT_DELIVERED_IMPS: 'Order Details - Change Delivered Imps Selected Product',
  ORDERS_SEARCH_CAMPAIGNS: 'Order Details - Search Campaigns',
  ORDERS_CHANGE_NUMBER_OF_ITEMS: 'Order Details - Change Number of Items Per Page in Campaign List',
  // PRODUCT SUMMARY
  PRODUCT_SUMMARY_SEARCH_CAMPAIGNS: 'Product Summary - Search Campaigns',
  PRODUCT_SUMMARY_CHANGE_NUMBER_OF_ITEMS: 'Product Summary - Change Number of Items Per Page',
  // NEW DAILY PERFORMANCE MODULE
  DAILY_PERFORMANCE_MODULE: 'Daily Performance module',
};
