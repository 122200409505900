export default {
  SET_CONVERSIONS: 'SET_CONVERSIONS',
  SET_DAILY_PERFORMANCE: 'SET_DAILY_PERFORMANCE',

  SET_CONVERSIONS_CACHE: 'SET_CONVERSIONS_CACHE',
  SET_IS_CONVERSIONS_CACHED: 'SET_IS_CONVERSIONS_CACHED',

  SET_CONVERSIONS_ERROR: 'SET_CONVERSIONS_ERROR',
  SET_DAILY_PERFORMANCE_ERROR: 'SET_DAILY_PERFORMANCE_ERROR',

  SET_IS_CONVERSIONS_LOADING: 'SET_IS_CONVERSIONS_LOADING',
  SET_IS_DAILY_PERFORMANCE_LOADING: 'SET_IS_DAILY_PERFORMANCE_LOADING',
};
